@import "./assets/less/fontawesome.less";
@import "./assets/less/solid.less";

@import (inline) "./assets/css/shards.css";

:root {
    --blue: #1e3969;
    --gold: #9b8a43;
    --black: #000;
}

body {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.428571429;
    color: var(--black);
    background: #fbfbfb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Bebas Neue', "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400 !important;
}

h3,
.h3 {
    font-size: 2.6rem;
}

h5,
.h5 {
    font-size: 1.8rem;
}

// bootstrap fix
.form-check.d-flex {
    .form-check-input {
        margin-top: 0;
    }
}

.strong,
strong {
    font-weight: bold;
}

.text-normal {
    font-weight: normal;
}

.btn-primary {
    color: #fff;
    background-color: #1b3969;
    border-color: #162e55;
}

.btn-round {
    font-size: .75rem;
    border-radius: 50%;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.form-group {

    .form-check,
    .custom-control {
        padding-bottom: 0.5rem;
    }

    .form-check:last-of-type,
    .custom-control:last-of-type,
    .custom-control-inline {
        padding-bottom: 0;

    }
}

#results {
    label {
        font-size: 1.05rem;
    }
}

.visually-hidden {
    visibility: hidden;
}